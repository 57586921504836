import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
html, body {
  margin: 0px;
  padding: 0px;
  min-height: 90%;
  height: 100%;
  font-family: 'Nunito', sans-serif;
  background: ${(props: { greyColor?: boolean }) => (props.greyColor ? '#f9f9f9' : '#fff')};
}

#root {
  height: 100%;
}
`

export const Wrapper = styled.div`
  min-height: 100%;
  height: auto !important;
  margin-bottom: -100px; /* the bottom margin is the negative value of the footer's total height */

  :after {
    content: '';
    display: block;
    height: 100px; /* the footer's total height */
  }
`
