import {
  Configuration,
  DocumentApi,
  DocumentRequestDto,
  FormSubmissionRequestDto,
  FormSubmissionsApi,
  LogsApi,
  SalesApi,
  SiteApi,
  VerifyAccessCodeRequest,
  VerifyAccessEmailRequest,
} from '@wisenet/salesapp-client'
import { SITE_PATH } from './../constants/common'

export let salesApi: SalesApi
export let logsApi: LogsApi
export let siteApi: SiteApi
export let formSubmissionApi: FormSubmissionsApi
export let documentApi: DocumentApi

export const setConfiguration = () => {
  const basePath = process.env.REACT_APP_API_BASE_PATH
  const headers = {
    'salesapp-url': `${process.env.REACT_APP_SALES_APP_URL}/${SITE_PATH}`,
  }
  const config = new Configuration({ basePath, headers })

  salesApi = new SalesApi(config)
  logsApi = new LogsApi(config)
  siteApi = new SiteApi(config)
  formSubmissionApi = new FormSubmissionsApi(config)
  documentApi = new DocumentApi(config)
}

export const cloudWatchLogger = {
  error: async (name: string, message?: string, stackTrace?: string) => {
    if (process.env.REACT_APP_LOG_CLOUD_WATCH === 'false') {
      return
    }

    return logsApi
      .create({
        logEntryDto: [
          {
            entryType: 'Error',
            message: `${name} - Failed With Error Message: ${message} - stack Trace : ${stackTrace}`,
          },
        ],
      })
      .catch((error: Error) => {
        console.error('cloudWatchLogger', error)
        throw error
      })
  },

  info: async (name: string, info: string) => {
    if (process.env.REACT_APP_LOG_CLOUD_WATCH === 'false') {
      return
    }

    return logsApi
      .create({
        logEntryDto: [
          {
            entryType: 'Info',
            message: `${name} : ${info}`,
          },
        ],
      })
      .catch((error: Error) => {
        console.error('cloudWatchLogger', error)
        throw error
      })
  },
}

export const sendOTP = async (guid: string, mobileNumber?: string, emailAddress?: string) => {
  return formSubmissionApi.createOneTimePassword({
    guid,
    mobileNumber,
    emailAddress,
  })
}

export const validateOTP = async (guid: string, otp: string) => {
  return formSubmissionApi.getSubmissionByOneTimePassword({
    guid,
    otp,
  })
}

export const validateAccessCode = async (params: VerifyAccessCodeRequest) => {
  try {
    const result: unknown = await salesApi.verifyAccessCode(params)
    return (result as string) === 'true'
  } catch (error: any) {
    cloudWatchLogger.error('API Error: validateAccessCode', error.message, error.stack)
    throw new Error('There was an issue verifying this access code')
  }
}

export const getPaymentStatus = async (guid: string) => {
  try {
    const result = await formSubmissionApi.queryPaymentStatus({ guid })
    return result
  } catch (error) {
    throw error
  }
}

export const validateEmail = async (params: VerifyAccessEmailRequest) => {
  try {
    const result: unknown = await salesApi.verifyAccessEmail(params)

    return (result as string) === 'true'
  } catch (error: any) {
    cloudWatchLogger.error('API Error: verifyAccessEmail', error.message, error.stack)
    throw new Error('There was an issue verifying this email')
  }
}

export const submitForm = async (data: FormSubmissionRequestDto) => {
  try {
    const payload = { formSubmissionRequestDto: [data] }

    const response = await formSubmissionApi.createFormSubmissions(payload)

    return response?.[0]
  } catch (error) {
    throw error /* <-- rethrow the error so consumer can still catch it */
  }
}

export const updateForm = async (guid: string, data?: FormSubmissionRequestDto) => {
  try {
    const payload = { guid, formSubmissionRequestDto: data }

    const response = await formSubmissionApi.replaceFormSubmission(payload)
    return response
  } catch (error) {
    throw error /* <-- rethrow the error so consumer can still catch it */
  }
}

export interface DocumentRequestResult {
  fileName: string
  presignedUrl: string
  type: string
  url: string
}

export const getPreSignedUrl = async (documentRequestDto: DocumentRequestDto) => {
  try {
    const response = await documentApi.createDocumentsRaw({
      documentRequestDto,
    })

    const data: DocumentRequestResult = await response.raw.json()
    return data
  } catch (error) {
    throw error /* <-- rethrow the error so consumer can still catch it */
  }
}

export const uploadDocument = async ({ file, data }: { file: File; data: DocumentRequestResult }) => {
  try {
    await fetch(data.presignedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    })

    return {
      fileName: data.fileName,
      type: file.type,
      s3Path: data.url,
    }
  } catch (error) {
    throw error /* <-- rethrow the error so consumer can still catch it */
  }
}

export const fetchForm = async ({ tenantCode, formGuid }: { tenantCode: string; formGuid: string }) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_ASSET_BASE_URL}/forms/${tenantCode}/${formGuid}.json`)
    if (res.status === 404) {
      throw new Error('Page not found')
    }
    return res
  } catch (error) {
    throw error /* <-- rethrow the error so consumer can still catch it */
  }
}
