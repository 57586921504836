import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { setConfiguration } from './api'
import App from './App'
import { AppProvider } from './contexts/AppContext'
import './index.css'
import { store } from './redux/store'

setConfiguration()

const container = document.getElementById('root')!
const root = createRoot(container)
export const client = new QueryClient()

root.render(
  <BrowserRouter>
    <QueryClientProvider client={client}>
      <Provider store={store}>
        <AppProvider>
          <App />
        </AppProvider>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </BrowserRouter>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
