import React, { useState } from 'react'

export const useInternet = () => {
  const [isConnected, setIsConnected] = useState(true)

  const handleChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline'
    if (condition === 'online') {
      const webPing = setInterval(() => {
        fetch('https//google.com', {
          mode: 'no-cors',
        })
          .then(() => {
            setIsConnected(true)
            return clearInterval(webPing)
          })
          .catch(() => {
            setIsConnected(false)
          })
      }, 2000)
      return
    }
    return setIsConnected(false)
  }

  React.useEffect(() => {
    window.addEventListener('online', handleChange)
    window.addEventListener('offline', handleChange)

    return () => {
      window.removeEventListener('online', handleChange)
      window.removeEventListener('offline', handleChange)
    }
  }, [])

  return isConnected
}
