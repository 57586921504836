import styled, { css } from 'styled-components'

// const hoverColor = '#337AB7' //used for chosen dd
// const headingPreviewColor = '#337AB7'
// const buttonPlusColor = '#337AB7'
// const muteText = '#cbcbcb'
// const headerLine = '#696969'
// const hilightBG = '#f8fdff'
export const borderColor = '#e6e8ea'
const muteLine = '#E3E3E3'
const borderRadius = '2px'
const formInputBG = '#ffff'
const formInputBorder = `1px solid ${borderColor}`

const inputIconColor = '#9d9d9d'

export const StyledFormGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;

  .long-field {
    grid-column: span 4;
  }
  .short-field {
    grid-column: span 1;
  }
  .medium-field {
    grid-column: span 2;
  }
`
export const FormElement = styled.div`
  margin-bottom: 15px;
  flex-direction: column;
`

export const InputBlock = css`
  box-sizing: border-box;
  border-radius: ${borderRadius} !important;
  border: ${formInputBorder};
  background-color: ${formInputBG};
  padding: 0 10px;
  color: #333333; // not important

  :focus-visible {
    outline: solid 1px ${props => props.theme.highlightColor};
  }
`

const DropdownInputBlock = css`
  > .fb__dropdown-input {
    .fb__dropdown-input__control {
      ${InputBlock}
    }
  }

  .fb__dropdown-input {
    .fb__dropdown-input__control {
      color: #4d4d4d;
      border-color: ${borderColor};
      padding: unset;
    }
    .fb__dropdown-input__menu {
      border-radius: 0;
      flex-direction: column;
      .fb__dropdown-input__option--is-focused {
        background-color: #38607140;
      }
      .fb__dropdown-input__option--is-selected {
        background-color: #386071;
      }
    }
    .fb__dropdown-input__control {
      border-radius: 0;
    }
    .fb__dropdown-input__control--is-focused {
      border-width: 1px;
      border-radius: 0;
      border-color: ${props => props.theme.highlightColor} !important;
      box-shadow: none;
    }

    .fb__dropdown-input__dropdown-indicator {
      color: #386071; // 606060
    }
  }
`

export const StyledFormInput = styled.div`
  textarea,
  input {
    width: 100%;
    line-height: 34px;
    ${InputBlock}
  }
`

export const StyledDateInput = styled(StyledFormInput)``

export const StyledTermsAndC = styled.div`
  flex-direction: column;
  div:first-child {
    max-height: 300px;
    overflow: scroll;
    margin-bottom: 15px;
    background-color: white;
    padding: 20px;
    box-shadow: 0 0 8px #aaa inset;
    border-radius: 3px;
    font-size: 12px;
    color: #555;
  }

  .accept-terms {
    flex-direction: row-revers;
    > label {
      margin-right: auto;
    }
    > input {
      margin-right: 8px;
    }
  }
`

export const StyledHTML = styled.div`
  margin-bottom: 15px;
  flex-direction: column;

  p {
    margin: unset;
  }

  div {
    display: unset;
    flex-direction: unset;
  }
`

export const StyledConfirmation = styled.div`
  display: flex;
  flex-direction: revert;
  align-items: center;
  background-color: #eee;
  padding: 0 10px;
  border-radius: 5px;
  position: relative;
  font-size: 14px;
  padding-bottom: 14px;
  padding-top: 14px;

  .accept-checkbox {
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid #ccc;
    position: absolute;
  }

  .required-star {
    margin-left: 40px;
    margin-top: 32px;
    height: 100%;
    position: absolute;
  }

  .condition-content {
    margin-left: 50px;
    display: unset;

    > p:first-child {
      margin-top: 0;
    }

    > p:last-child {
      margin-bottom: 0;
    }
  }
`

export const StyledFancyLabel = styled.div`
  font-size: 16px;
  padding-bottom: 5px;
  width: 100%;
  border-bottom: 2px solid ${props => props.theme.highlightColor};
  font-weight: 500;
  color: #333333;
  margin-bottom: 15px;
`

export const StyledFormLabel = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 11pt;
  margin-bottom: 3px;

  .element-additional-info {
    font-size: 12px;
    color: #555;

    p {
      margin: unset;
    }

    div {
      display: unset;
      flex-direction: unset;
    }
  }
`

export const StyledFormPage = styled.div`
  display: flex;
  flex-direction: column;
  display: ${({ show }: { show: boolean }) => (show ? 'flex' : 'none')};
`

export const StyledFormSelect = styled.div`
  display: flex;
  flex-direction: column;

  .fb__dropdown-input__control {
    width: 100%;
  }

  .fb__dropdown-input__menu {
    z-index: 20;
  }

  .hidden {
    display: none;
  }

  ${DropdownInputBlock}
`

export const StyledFormButtons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  button,
  input[type='submit'] {
    color: ${props => props.theme.buttonColor};
    font-size: 11pt;
    background: ${props => props.theme.buttonFill};
    border-radius: 2px;
    padding: 10px 20px;
    margin-right: 10px;
    border: 0;
    text-decoration: none;
    cursor: auto;
    filter: ${({ disabled }: { disabled?: boolean }) => disabled && 'grayscale(75%)'};
    width: max-content;
    max-width: 250px;
    min-width: 100px;

    :disabled {
      filter: grayscale(75%);
    }

    :not(:disabled) {
      :hover {
        cursor: pointer;
        background: ${props => !props.disabled && props.theme.highlightColor};
        animation: background 0.2s ease-in;
      }
    }
  }

  > a {
    color: ${props => props.theme.highlightColor};
    cursor: pointer;
    font-weight: bold;
  }
`

export const FormWizard = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${muteLine};
  margin-bottom: 20px;

  .progress-container {
    margin-right: 20px;

    .progress-ring {
      --ringSize: 6rem;
      --mainColor: ${props => props.theme.highlightColor};
      --ringBg: #eee;
      --coreBg: #f9f9f9;
      --currentStep: ${(props: { currentStep: number; totalSteps: number }) => props.currentStep};
      --totalSteps: ${(props: { currentStep: number; totalSteps: number }) => props.totalSteps};
      border-radius: 50%;
      display: grid;
      place-items: center;
      width: var(--ringSize);
      height: var(--ringSize);
      background: radial-gradient(closest-side, var(--coreBg) 80%, transparent 0 99.9%, var(--coreBg) 0),
        conic-gradient(var(--mainColor) calc((var(--currentStep) / var(--totalSteps) * 100) * 1%), var(--ringBg) 0);
      font-size: calc(var(--ringSize) / 5);
      color: var(--mainColor);

      :before {
        counter-reset: curr var(--currentStep) tota var(--totalSteps);
        content: counter(curr) ' of ' counter(tota);
        color: black;
      }
    }
  }

  .wizard-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3.wizard-step {
      margin-top: 0;
      margin-bottom: 10px;
    }

    .wizard-next {
      font-size: 13px;
      color: #555;
    }
  }
`

export const StyledSignature = styled.div`
  box-sizing: border-box;

  .signature-pad {
    box-sizing: border-box;
    padding: 10px 0;
    background: #fff;
  }

  .clear-button-container {
    box-sizing: border-box;
    bottom: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: flex-end;
    background: #fff;
    margin-top: -4px;

    > button {
      margin: 10px;
      color: ${props => props.theme.buttonFill};
      background-color: transparent;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      overflow: hidden;
      outline: none;

      :hover {
        color: ${props => props.theme.highlightColor};
      }
    }
  }
`

export const StyledPhoneInput = styled.div`
  input {
    width: 100%;
    line-height: 34px;
    ${InputBlock}
  }

  .intl-tel-input {
    display: flex;

    .selected-flag {
      isolation: isolate;
    }
  }
  .error {
    color: red;
    font-size: 8pt;
    height: 0;
  }
`

export const StyledRadioInput = styled.div`
  flex-direction: row;
  margin-right: 15px;
  align-items: center;

  > input.form-control {
    margin: 0;
    margin-right: 5px;
  }
`

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isFocused) {
    return '#2196f3'
  }
  return '#eeeeee'
}

export const StyledDropzone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-color: ${props => getColor(props)};
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  border: 1px dashed #386071;
  background-color: white;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;

  .upload-container {
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      width: 75px;
    }
  }

  .upload-container-text {
    margin-bottom: 5px;
  }
  span {
    font-size: 11pt;
    margin-bottom: 5px;
  }

  .dz-message {
    display: none;
  }
  .dz-preview {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 135px;

    > img {
      width: 75px;
      margin: auto;
      margin-right: 0;
    }

    .file-container {
      display: flex;
      flex-direction: column;
      margin: auto;
      margin-left: 20px;

      .file-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: auto;

        p.name {
          margin-right: 10px;

          i {
            margin-left: 10px;
            color: ${props => props.theme.buttonFill};
            cursor: pointer;
            margin-left: 10px;

            :hover {
              color: ${props => props.theme.highlightColor};
            }
          }
        }
      }

      .error.text-danger {
        margin-bottom: auto;
      }
    }
  }

  .dz-complete {
    .dz-image {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
    }

    .dz-remove {
      color: ${props => props.theme.buttonFill};

      :hover {
        color: ${props => props.theme.highlightColor};
      }
    }
  }
`

export const StyledDropzonePreview = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`

export const StyledHeader = styled.div`
  h2 {
    font-size: 16px;
    padding-bottom: 5px;
    width: 100%;
    border-bottom: 2px solid ${props => props.theme.highlightColor};
    font-weight: 500;
    color: #333333;
  }
`

export const StyledFormBuilder = styled.div`
  margin: auto;
  width: 60%;
  padding: 30px 10px;
  box-sizing: border-box;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

export const StyledResumeForm = styled.div`
  width: 50vw;
  margin-top: 10px;
  padding: 10px;

  .confirmation {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: auto;

    input[type='checkbox'],
    i {
      margin: 2px 5px auto 0;
    }
    &.italic {
      font-style: italic;
      font-size: 11pt;
    }
  }

  .modal-footer {
    margin-top: 20px;
    display: flex;
    gap: 10px;

    button {
      color: ${props => props.theme.buttonColor};
      font-size: 11pt;
      background: ${props => props.theme.buttonFill};
      border-radius: 2px;
      padding: 10px 20px;
      margin-left: auto;
      border: 0;
      text-decoration: none;
      cursor: auto;
      filter: ${({ disabled }: { disabled?: boolean }) => disabled && 'grayscale(75%)'};
      width: max-content;
      min-width: 100px;
      height: 50px;

      :disabled {
        filter: grayscale(75%);
      }

      :not(:disabled) {
        :hover {
          cursor: pointer;
          background: ${props => !props.disabled && props.theme.highlightColor};
          animation: background 0.2s ease-in;
        }
      }
    }
  }

  .loader {
    margin: auto;
    margin-top: 200px;
    margin-bottom: 200px;
    color: ${props => props.theme.highlightColor};
    font-size: 10px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: mulShdSpin 1.3s infinite linear;
    transform: translateZ(0);
  }

  @media only screen and (max-width: 600px) {
    width: auto;
    margin-top: 0;
    padding: 0;

    .confirmation {
      input[type='checkbox'] {
        width: 50px;
      }
    }
  }
`

export const StyledApplicationSaved = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 20px;

  h2 {
    font-size: 32px;
    padding: 0;
    width: auto;
    border: 0;
    font-weight: bolder;
    margin: 0;
  }

  h3.sr-course-name {
    color: ${props => props.theme.highlightColor};
  }

  .button-panel {
    flex-direction: row;
    display: flex;
    gap: 10px;
    margin-top: 0;

    button {
      :not(:first-child) {
        margin-left: 20px;
      }
    }
  }
`

export const CustomDateInput = styled.div`
  i {
    position: absolute;
    padding: 10px;
    min-width: 40px;
    margin-left: -35px;
    color: ${inputIconColor};
  }
`

export const StyledErrorProviderHOC = styled.div`
  margin-bottom: ${({ error }: { error: boolean }) => (error ? '-10px' : '0')};

  input,
  textarea {
    border-color: ${({ error }: { error: boolean }) => (error ? 'red' : borderColor)};
  }
  .fb__dropdown-input__control {
    border: ${({ error }: { error: boolean }) => (error ? '1px solid red !important' : '1px solid')};
  }
`

export const StyledSubmissionSuccess = styled.div`
  h3 {
    margin: unset;
  }

  i {
    color: green;
    margin-right: 5px;
    font-size: 16pt;
  }
`

export const StyledGroupHeader = styled.div`
  h2 {
    font-size: 16px;
    padding-bottom: 5px;
    width: 100%;
    font-weight: 600;
    color: #333333;
    grid-column: 1 / 3;
  }
`
export const StyledGroupCheckbox = styled.div`
  margin-bottom: 15px;

  input {
    margin-left: 0px;
  }
`
