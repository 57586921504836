import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { SalesIntakeApplicationTypeDto, SalesIntakeDto } from '@wisenet/salesapp-client'
import dayjs from 'dayjs'
import { cloudWatchLogger, salesApi } from '../api'
import { LoadingStates, RootState } from '../types/assets'

type IntakeSlice = {
  loading: LoadingStates
  intakes: SalesIntakeDto[]
}

export const fetchIntake = createAsyncThunk<SalesIntakeDto, string, any>(
  'intakes/fetchIntakes',
  async (guid, thunkAPI) => {
    try {
      const intake: SalesIntakeDto = await salesApi.getIntakes({ guid })
      cloudWatchLogger.info('Fetched Intake', intake.intakeGuid || 'No Intake ID found')
      return intake
    } catch (error: any) {
      throw error
    }
  },
)

export const initialState: IntakeSlice = {
  loading: 'idle',
  intakes: [],
}

export const intakeSlice = createSlice({
  name: 'intakes',
  initialState,
  reducers: {
    resetLoader: (state, action) => {
      state.loading = 'idle'
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchIntake.fulfilled, (state, action) => {
      state.loading = 'succeeded'
      state.intakes.push(action.payload)
    })
    builder.addCase(fetchIntake.pending, (state, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchIntake.rejected, (state, actions) => {
      state.loading = 'failed'
    })
  },
})

export const selectIntakesFromCourseId = createSelector(
  (state: RootState) => state.intakes.intakes,
  (state: RootState, courseGuid: string) => courseGuid,
  (intakes: SalesIntakeDto[], courseGuid: string) =>
    intakes.filter((intake: SalesIntakeDto) => intake.intakeGuid === courseGuid),
)

export const selectIntakeById = createSelector(
  (state: RootState) => state.intakes.intakes,
  (state: RootState, id: string) => id,
  (intakeDetails: SalesIntakeDto[], id: string) =>
    intakeDetails.filter((intake: SalesIntakeDto) => intake.intakeGuid === id)[0],
)

export const selectApplicationTypeOptions = createSelector(
  (state: RootState, id: string) => selectIntakeById(state, id),
  (intake: SalesIntakeDto) =>
    intake?.salesIntakeApplicationTypes
      ?.filter((appType: SalesIntakeApplicationTypeDto) =>
        dayjs().isBetween(
          appType?.accessStartDate ?? dayjs().subtract(1, 'days'),
          appType?.accessEndDate ?? dayjs().add(1, 'days'),
          'day',
          '[]',
        ),
      )
      .map((appType: SalesIntakeApplicationTypeDto) => ({
        label: appType?.applicationTypeName,
        value: appType?.applicationTypeId,
      })),
)

export const { resetLoader } = intakeSlice.actions

export default intakeSlice.reducer
