import { AdditionalIntakeDto, FormSubmissionResponseDto } from '@wisenet/salesapp-client'
import { createContext, ReactNode, useState } from 'react'

interface IAppProvider {
  children: ReactNode
}

export type ApplicationData = {
  intakeName?: string
  intakeGuid?: string
  courseOfferId?: number
  applicationTypeId?: number
  applicationType?: string
  formGuid?: string
  paymentRequired?: boolean
  applicationLinkGuid?: string
  opportunityId?: string
  additionalIntakes?: AdditionalIntakeDto[]
  formSubmissionGuid?: string
  formData?: FormSubmissionResponseDto
}

interface IAppContext extends ApplicationData {
  updateAppData: (appData: ApplicationData) => void
}

export const AppContext = createContext<IAppContext>({
  updateAppData: () => {},
})

export const AppProvider = ({ children }: IAppProvider) => {
  const [appData, setAppData] = useState<ApplicationData>()

  const updateAppData = (appData: ApplicationData) => {
    setAppData(appData)
  }

  return <AppContext.Provider value={{ ...appData, updateAppData }}>{children}</AppContext.Provider>
}
