import styled from 'styled-components'
import { ButtonProps } from './Button'

export type NavButtonProps = ButtonProps & {
  activePage?: boolean
}

export const StyledNavButton = styled.li`
  list-style: none;
  box-sizing: border-box;
  a {
    margin-left: 3vw;
    padding: 10px 0;
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
    color: ${props => props.theme.topBannerColor};

    :hover {
      border-bottom: 3px solid ${props => props.theme.topBannerColor};
    }
  }

  .active {
    border-bottom: 3px solid ${props => props.theme.topBannerColor};
  }
`

export function NavButton({ ...buttonProps }: NavButtonProps) {
  return <StyledNavButton>{buttonProps.label}</StyledNavButton>
}
