import { useEffect, useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'
import 'react-loading-skeleton/dist/skeleton.css'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { fetchContactUs } from './redux/contactUsSlice'
import { selectCourseFromCourseId } from './redux/courseSlice'
import { selectIntakeById } from './redux/intakeSlice'
import { fetchSettings } from './redux/settingsSlice'
import { AppRoutes } from './Routes'
import { useAppSelector } from './types/assets'

const generatePageTitle = (siteName: string, location: string, courseName: string, intakeName: string) => {
  const urlArray = location.split('/')

  const page = urlArray[2]
  const objectId = urlArray[3]

  let title = siteName || 'Sales+'

  if (siteName) {
    if (page === 'courses' && !objectId) {
      title = `Courses - ${siteName}`
    } else if (page === 'courses' && !!objectId) {
      title = `${courseName || 'Course'} - ${siteName}`
    } else if (page === 'intake' && !objectId) {
      title = `Intake - ${siteName}`
    } else if (page === 'intake' && !!objectId) {
      title = `${intakeName || 'intake'} - ${siteName}`
    } else {
      title = `${page ? `${page?.replace(/\b\S/g, t => t.toUpperCase())} -` : ''} ${siteName}`
    }
  }
  return title
}

function App() {
  const {
    settings: {
      loading,
      config: { tenantCode, siteName, gaMeasurementId, metaPixelId },
    },
  } = useAppSelector(state => state)

  const dispatch = useDispatch()
  const location = useLocation()
  const splitPage = location.pathname.split('/')
  const [title, setTitle] = useState('Sales+')

  const { course, intake } = useAppSelector(state => {
    return {
      course: selectCourseFromCourseId(state, splitPage[3] as string),
      intake: selectIntakeById(state, splitPage[3] as string),
    }
  })

  useEffect(() => {
    if (metaPixelId) {
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: true, // enable logs
      }
      ReactPixel.init(metaPixelId, undefined, options)
      ReactPixel.pageView() // For tracking page view
    }
  }, [metaPixelId])

  useEffect(() => {
    if (gaMeasurementId) {
      ReactGA.initialize(gaMeasurementId)
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
      })
    }
  }, [gaMeasurementId, location])

  useEffect(() => {
    if (loading === 'idle') {
      dispatch(fetchSettings())
    }
  }, [dispatch, loading])

  useEffect(() => {
    if (tenantCode && loading === 'succeeded') {
      dispatch(fetchContactUs())
    }
  }, [dispatch, tenantCode, loading])

  useEffect(() => {
    setTitle(generatePageTitle(siteName!, location.pathname, course?.publishName!, intake?.publishName!))
  }, [siteName, location, splitPage, course?.publishName, intake?.publishName])

  useEffect(() => {
    document.title = title
  }, [title])

  useEffect(() => {
    var recaptchaEl = document.createElement('script')
    recaptchaEl.setAttribute('type', 'text/javascript')
    recaptchaEl.setAttribute(
      'src',
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`,
    )

    document.head.append(recaptchaEl)
  }, [])

  return (
    <>
      <Helmet>
        <script
          src="https://kit.fontawesome.com/3a5f3ec3aa.js"
          crossOrigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
          crossOrigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
          crossOrigin="anonymous"
        />
      </Helmet>
      <AppRoutes />
      <ReactTooltip delayShow={300} />
    </>
  )
}

export default App
