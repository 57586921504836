import styled from 'styled-components'

export type HeadingProps = {
  size: 'XXL' | 'XL' | 'L' | 'M' | 'S' | 'XS'
  children: string
} & React.ComponentProps<'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'>

const StyledH1 = styled.h1``

const StyledH2 = styled.h2``

const StyledH3 = styled.h3`
  font-weight: 600;
  font-size: 28px;
  margin: 24px 0;
`

const StyledH4 = styled.h4``

const StyledH5 = styled.h5``

const StyledH6 = styled.h6``

export function Heading({ size, children }: HeadingProps) {
  if (size === 'XXL') return <StyledH1>{children}</StyledH1>
  if (size === 'XL') return <StyledH2>{children}</StyledH2>
  if (size === 'L') return <StyledH3>{children}</StyledH3>
  if (size === 'M') return <StyledH4>{children}</StyledH4>
  if (size === 'S') return <StyledH5>{children}</StyledH5>
  if (size === 'XS') return <StyledH6>{children}</StyledH6>

  throw new Error()
}
