import { memo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useLocation } from 'react-router-dom'
import { Logo, StyledLogo } from '../../elements/Logo'
import { useAppSelector } from '../../types/assets'
import { NavButtons } from './NavButtons'
import { StyledNavBar, StyledNavBarContainer, StyledNavButtons } from './styles'

const pagesToHideNavbar = ['intake', 'application', 'payments']

const UnMemoizedNavBar = () => {
  const location = useLocation()
  const splitPage = location.pathname.split('/')

  const state = useAppSelector(state => state)

  const {
    settings: {
      loading,
      theme: { headerLogoSrc },
    },
  } = state

  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const hideNavButtons = pagesToHideNavbar.includes(splitPage[2])

  const toggleHamburgerIcon = () => {
    setShowMobileMenu(!showMobileMenu)
  }

  return (
    <StyledNavBarContainer>
      {loading === 'loading' ? (
        <NavSkeleton />
      ) : (
        <StyledNavBar>
          <Logo src={headerLogoSrc ?? ''} />
          {!hideNavButtons && (
            <>
              <NavButtons />
              <div
                id={`hamburger-icon`}
                className={`${showMobileMenu && 'open'}`}
                onClick={toggleHamburgerIcon}
              >
                <div className={`bar bar1 ${showMobileMenu && 'open'}`} />
                <div className={`bar bar2 ${showMobileMenu && 'open'}`} />
                <div className={`bar bar3 ${showMobileMenu && 'open'}`} />
                <div className={`mobile-menu ${showMobileMenu && 'open'}`}>
                  <NavButtons />
                </div>
              </div>
            </>
          )}
        </StyledNavBar>
      )}
    </StyledNavBarContainer>
  )
}

export const NavSkeleton = () => {
  return (
    <StyledNavBar>
      <StyledLogo>
        <Skeleton
          height="80px"
          width="230px"
          style={{ margin: '10px 0' }}
        />
      </StyledLogo>
      <StyledNavButtons>
        <Skeleton
          width="100px"
          style={{ margin: '0 10px' }}
        />
        <Skeleton
          width="100px"
          style={{ margin: '0 10px' }}
        />
      </StyledNavButtons>
    </StyledNavBar>
  )
}

export const NavBar = memo(UnMemoizedNavBar)
