import { Action, ThunkAction } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { store } from '../redux/store'

export type Nullable<T> = T | null | undefined
export type SiteStatus = undefined | 'Inactive' | 'Test' | 'Live'
export type LoadingStates = 'idle' | 'loading' | 'succeeded' | 'failed'
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export type ContactForm = {
  id: string
  formGuid: string
  displayLabel: string
  contactInstructionText: string
  contactFormLink: string
}

export type Settings = {
  brandingColors: {
    topBannerFillColor: `#${string}`
    topBannerFontColor: `#${string}`
    buttonColor: `#${string}`
    buttonFontColor: `#${string}`
    highlightColor: `#${string}`
  }
  headerLogo: string
  favicon: string
  banner: string
}
export type ContactUs = {
  selectedForm: Nullable<ContactForm>
  pageName: Nullable<string>
  defaultContactForm: Nullable<ContactForm>
  additionalContactForms: Nullable<ContactForm[]>
}

export const DefaultThemeParams = {
  topBannerFill: '#f3f3f3',
  topBannerColor: '#4D4D4D',
  buttonFill: '#363131',
  buttonColor: '#FFFFFF',
  highlightColor: '#E23C39',
  maxWidth: '960px',
  fontColor: '#575757',
}
