import { Banner } from '../../elements/Banner'
import { Heading } from '../../elements/Heading'
import { Paragraph } from '../../elements/Paragraph'
import { StyledBannerButtons } from '../BannerButtons'

const defaultBannerImageSrc = 'https://i.imgur.com/fVDwdGX.png'
const defaultContactInstructionText =
  'Please complete the form below. A dedicated course consultant will be in touch with you shortly.'

export type ContactUsBannerProps = {
  showHeader?: boolean
  bannerImageSrc?: string
  contactUsInstructionText?: string
  children: React.ReactNode
}

export function ContactUsBanner({
  showHeader,
  bannerImageSrc = defaultBannerImageSrc,
  contactUsInstructionText = defaultContactInstructionText,
  children: BannerButtons,
}: ContactUsBannerProps) {
  return (
    <Banner
      imageURL={bannerImageSrc}
      left={false}
    >
      {showHeader && <Heading size="L">Choose your reason for Contacting Us</Heading>}
      <StyledBannerButtons>{BannerButtons}</StyledBannerButtons>
      <Paragraph>{contactUsInstructionText}</Paragraph>
    </Banner>
  )
}
