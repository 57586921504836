import { getObjectkeys } from '../utils/common'

export const ASSET_PATHS = {
  tenantUrls: 'tenant-urls.json',
  brandingColors: `css-variables.json`,
  headerLogo: `header-logo.png`,
  favicon: `favicon-icon.ico`,
  banner: `contact-us-banner-image.jpeg`,
  contactUs: 'contact-us-settings.json',
  industries: 'industries.json',
  locations: 'locations.json',
  intakesDetailed: 'intakes-detailed.json',
  deliveryOptions: 'delivery-options.json',
} as const

export const ASSET_PATH_KEYS = getObjectkeys(ASSET_PATHS)

export const TENANT_DETAILS_URL = `${process.env.REACT_APP_ASSET_BASE_URL}/${process.env.REACT_APP_URL_SUFFIX}/tenant-urls.json`
