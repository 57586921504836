import { memo } from 'react'
import wisenetLogo from '../assets/wisenet-logo.svg'
import { StyledFooter } from './style'

const UnMemoizedFooter = () => {
  return (
    <StyledFooter>
      <div>
        <div>Powered By</div>
        <img
          src={wisenetLogo}
          alt="Wisenet Logo"
        />
      </div>
    </StyledFooter>
  )
}

export const Footer = memo(UnMemoizedFooter)
