import styled from 'styled-components'
import loader from '../assets/loader.svg'

const StyledLoader = styled.div`
  width: 120px;
  margin: auto;
  padding-top: 35vh;
  padding-left: 50px;
  min-height: 400px;
  img {
    height: 100%;
  }
`

export const Loader = () => {
  return (
    <StyledLoader className="loader">
      <img
        src={loader}
        alt="Loader"
      />
    </StyledLoader>
  )
}
