import { combineReducers, configureStore } from '@reduxjs/toolkit'
import contactUsSlice from './contactUsSlice'
import courseSlice from './courseSlice'
import intakeSlice from './intakeSlice'
import settingsSlice from './settingsSlice'

export const rootReducer = combineReducers({
  settings: settingsSlice,
  contactUs: contactUsSlice,
  courses: courseSlice,
  intakes: intakeSlice,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
