import styled from 'styled-components'
import { useInternet } from '../hooks/useInternet'

const StyledNoNetworkBanner = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
  background-color: red;
  text-align: center;
  z-index: 2;
  transition: height 1s ease;
  color: #fff;
  height: ${({ isConnected }: { isConnected?: boolean }) => (isConnected ? '0' : '50px')};
`

export const NoNetworkBanner = () => {
  const isConnected = useInternet()

  return (
    <StyledNoNetworkBanner isConnected={isConnected}>
      {!isConnected && (
        <div className="internet-error">
          <p>Internet connection lost</p>
        </div>
      )}
    </StyledNoNetworkBanner>
  )
}
