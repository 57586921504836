import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { siteApi } from '../api'
import { SITE_PATH } from '../constants/common'
import { LoadingStates, Nullable, Settings, SiteStatus } from '../types/assets'
import { getAssets } from '../utils/assets'

type SettingsSlice = {
  loading: LoadingStates
  config: {
    path: string
    tenantCode: Nullable<string>
    siteGuid: Nullable<string>
    siteUrl: Nullable<string>
    siteName: Nullable<string>
    lastModifiedTimestamp: Nullable<Date>
    siteStatus: SiteStatus
    allowCourses: Nullable<boolean>
    allowContact: Nullable<boolean>
    countryCode: Nullable<string>
    stripePublicKey: Nullable<string>
    isPaymentDemoMode: Nullable<boolean>
    gaMeasurementId: Nullable<string>
    metaPixelId: Nullable<string>
  }
  theme: {
    brandingColors: Nullable<Settings['brandingColors']>
    faviconSrc: Nullable<Settings['favicon']>
    headerLogoSrc: Nullable<Settings['headerLogo']>
    contactUsBannerSrc: Nullable<Settings['banner']>
  }
}

export const fetchSettings = createAsyncThunk<SettingsSlice>('settings/fetchSettings', async () => {
  const { tenantCode, siteGuid, lastModifiedTimestamp, ...rest }: any = await siteApi.getSite()
  const assets = await getAssets(tenantCode, siteGuid, lastModifiedTimestamp)

  return {
    loading: 'succeeded',
    config: {
      path: SITE_PATH,
      tenantCode: tenantCode,
      siteGuid: siteGuid,
      lastModifiedTimestamp,
      ...rest,
    },
    theme: {
      brandingColors: assets.brandingColors,
      faviconSrc: assets.favicon,
      headerLogoSrc: assets.headerLogo,
      contactUsBannerSrc: assets.banner,
    },
  }
})

const initialState: SettingsSlice = {
  loading: 'idle',
  config: {
    tenantCode: undefined,
    siteUrl: undefined,
    siteName: undefined,
    siteGuid: undefined,
    siteStatus: undefined,
    lastModifiedTimestamp: undefined,
    path: '',
    allowCourses: undefined,
    allowContact: undefined,
    countryCode: undefined,
    stripePublicKey: undefined,
    isPaymentDemoMode: undefined,
    gaMeasurementId: undefined,
    metaPixelId: undefined,
  },
  theme: {
    brandingColors: undefined,
    faviconSrc: undefined,
    headerLogoSrc: undefined,
    contactUsBannerSrc: undefined,
  },
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSettings.pending, (state, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchSettings.fulfilled, (state, action) => {
      state.loading = 'succeeded'
      state.config = action.payload.config
      state.theme = action.payload.theme
    })
    builder.addCase(fetchSettings.rejected, (state, action) => {
      state.loading = 'failed'
    })
  },
})

export default settingsSlice.reducer
