import styled from 'styled-components'

export const StyledNavBarContainer = styled.div`
  width: 100vw;
  background-color: ${props => props.theme.topBannerFill};
  color: ${props => props.theme.topBannerColor};
  box-shadow: ${props => `0px 2px 8px 0px ${props.theme.topBannerFill}A6`};
`

export const StyledNavBar = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  max-width: ${props => props.theme.maxWidth};
  max-height: 100px;
  min-height: 70px;
  padding: 0 4vw;
  justify-content: space-between;

  #hamburger-icon {
    margin: auto 0;
    display: none;
    cursor: pointer;

    .bar {
      width: 35px;
      height: 3px;
      background-color: ${props => props.theme.topBannerColor};
      margin: 6px 0;
      transition: 0.4s;
    }

    &.open {
      .bar1 {
        transform: rotate(-45deg) translate(-6px, 6px);
      }
      .bar2 {
        opacity: 0;
      }
      .bar3 {
        transform: rotate(45deg) translate(-6px, -8px);
      }
    }
  }

  .mobile-menu {
    display: none;
    position: absolute;
    left: 0;
    width: 100%;
    background-color: ${props => props.theme.topBannerFill}bb;
    border: 1px solid ${props => props.theme.topBannerColor};
    border-left: 0;
    border-right: 0;
    margin-top: 14px;
    text-align: center;
    z-index: 2;

    backdrop-filter: blur(7px);
    ul {
      margin: unset;
      width: 100%;
      padding: 0;

      li {
        padding: 20px;
        border-bottom: 1px solid ${props => props.theme.topBannerColor}bb;
        &:last-child {
          border-bottom: 0px;
        }

        a {
          color: ${props => props.theme.topBannerColor};
          margin: 0;

          &.active {
            border: none;
            font-weight: 600;
          }
        }
      }
    }

    &.open {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      ul {
        display: block;
      }

      ul li:hover {
        transform: scale(1.1);
        transition: 0.3s;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    max-height: 70px;
    min-height: 50px;

    img {
      max-height: 60px;
    }

    ul {
      display: none;
    }

    #hamburger-icon {
      display: block;
    }
  }
`

export const StyledNavButtons = styled.ul`
  display: flex;
  margin-left: auto;
  padding-left: 3vw;
`
