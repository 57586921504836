import React from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { useNavigate } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

export const StyledButton = styled.div`
  text-align: center;
  margin-top: auto;
  margin-bottom: 5px;
  button {
    color: ${props => props.theme.buttonColor};
    font-size: ${({ large }: { large?: boolean }) => (large ? '13pt' : '11pt')};
    background: ${props => props.theme.buttonFill};
    border-radius: 2px;
    padding: ${({ large }: { large?: boolean; disabled?: boolean }) => (large ? '14px 30px' : '10px 20px')};
    border: 0;
    text-decoration: none;
    cursor: pointer;
    filter: ${props => props.disabled && 'grayscale(75%)'};
  }

  .fa {
    height: 15px;
    width: 15px;
    margin-left: 8px;
    margin-right: -10px;
    line-height: unset;
  }

  button:hover {
    background: ${props => !props.disabled && props.theme.highlightColor};
    animation: background 0.2s ease-in;
  }
`

export type ButtonProps = {
  label: string
  to?: string
  onClick?: () => void
  disabled?: boolean
  large?: boolean
  tooltip?: string
  showSpinner?: boolean
}
export const Button = ({ onClick, label, to, disabled, large, tooltip, showSpinner }: ButtonProps) => {
  const navigate = useNavigate()

  const onButtonClick = (e: any) => {
    e.preventDefault()
    if (!disabled) {
      if (!!to) {
        navigate(to)
      } else if (onClick) {
        onClick()
      }
    }
  }

  React.useEffect(() => {
    ReactTooltip.rebuild()
  }, [tooltip])

  return (
    <>
      <StyledButton
        disabled={disabled}
        large={large}
      >
        <button
          onClick={onButtonClick}
          data-tip={tooltip}
          data-place="top"
          data-effect="solid"
        >
          {label}
          {showSpinner && <i className="fa fa-solid fa-circle-notch fa-spin"></i>}
        </button>
      </StyledButton>
    </>
  )
}
