import { memo } from 'react'
import { NavLink } from 'react-router-dom'
import { StyledNavButton } from '../../elements/NavButton'
import { ContactForm, useAppSelector } from '../../types/assets'
import { urlify } from '../../utils/common'
import { StyledNavButtons } from './styles'

const UnMemoizedNavButtons = () => {
  const selectedForm = useAppSelector(state => state.contactUs.contactUs.selectedForm) as ContactForm

  const {
    settings: {
      config: { allowContact, allowCourses },
    },
    contactUs: {
      contactUs: { pageName },
      loading,
    },
  } = useAppSelector(state => state)

  return (
    <StyledNavButtons>
      {allowCourses && (
        <StyledNavButton key="course">
          <NavLink
            key="course"
            className={({ isActive }) => (isActive ? 'active' : '')}
            to="courses"
          >
            Courses
          </NavLink>
        </StyledNavButton>
      )}
      {allowContact && loading === 'succeeded' && (
        <StyledNavButton key="contact">
          <NavLink
            key="contact"
            className={({ isActive }) => (isActive ? 'active' : '')}
            to={`contact/${urlify(selectedForm?.displayLabel)}`}
          >
            {pageName || 'Contact'}
          </NavLink>
        </StyledNavButton>
      )}
    </StyledNavButtons>
  )
}

export const NavButtons = memo(UnMemoizedNavButtons)
