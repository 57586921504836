import styled from 'styled-components'
import courseLG from '../assets/course-lg.jpg'

type BannerProps = {
  imageURL: string
  children?: React.ReactNode
  left?: boolean
}

export const StyledBannerContainer = styled.div`
  width: 100vw;
  height: max-content;
  min-height: 494px;
  background: url(${(props: { backgroundImage?: any }) => props.backgroundImage}), url(${courseLG});
  color: white;
  background-size: cover;

  h3 {
    font-weight: 400;
  }
  @media only screen and (max-width: 600px) {
    height: unset;

    h3 {
      font-size: 18pt;
    }
  }
`

const StyledBackgroundContainerGradient = styled.div`
  height: 100%;
  min-height: 494px;
  background: linear-gradient(184.37deg, rgba(0, 0, 0, 0.637) 52.26%, rgba(0, 0, 0, 0.371) 113.98%);
  display: flex;
`

export const StyledBanner = styled.div`
  box-sizing: border-box;
  max-width: ${props => props.theme.maxWidth};
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: ${({ left }: { left: boolean }) => (left ? 'flex-start' : 'center')};
  padding: ${({ left }: { left: boolean }) => (left ? 'auto' : '80px')};
  text-align: ${({ left }: { left: boolean }) => (left ? 'justify' : 'center')};

  p {
    height: max-content;
    line-height: 23pt;
  }
  @media only screen and (max-width: 600px) {
    padding: 30px 10px;
  }
`

export function Banner({ imageURL, children, left }: BannerProps) {
  return (
    <StyledBannerContainer backgroundImage={imageURL}>
      <StyledBackgroundContainerGradient>
        <StyledBanner left={left!}>{children}</StyledBanner>
      </StyledBackgroundContainerGradient>
    </StyledBannerContainer>
  )
}
