import { ASSET_PATHS } from '../constants/assets'
import { Nullable, Settings } from '../types/assets'
import { fetchJSON, getUnixTimestampForDate } from './common'

export const getAssetURLGetter = (
  tenantCode: string | undefined,
  siteGuid: string | undefined,
  lastModifiedTimeStamp: Date,
) => {
  return (assetPath: (typeof ASSET_PATHS)[keyof typeof ASSET_PATHS]) =>
    `${process.env.REACT_APP_ASSET_BASE_URL}/${
      process.env.REACT_APP_URL_SUFFIX
    }/${tenantCode}/${siteGuid}/${assetPath}?ignore=${getUnixTimestampForDate(lastModifiedTimeStamp)}`
}

export const getAssets = async (
  tenantCode: string | undefined,
  siteGuid: string | undefined,
  lastModifiedTimeStamp: Date,
): Promise<Settings> => {
  try {
    const getAssetURL = getAssetURLGetter(tenantCode, siteGuid, lastModifiedTimeStamp)

    const brandingColors: Settings['brandingColors'] = await fetchJSON(getAssetURL(ASSET_PATHS.brandingColors))

    return {
      brandingColors,
      favicon: getAssetURL(ASSET_PATHS.favicon),
      headerLogo: getAssetURL(ASSET_PATHS.headerLogo),
      banner: getAssetURL(ASSET_PATHS.banner),
    }
  } catch (e) {
    console.error(e)

    throw new Error('Asset fetch failed.')
  }
}

//Generated the URL to fetch the images
export const courseImgURLGetter = (tenantCode: Nullable<string>, size: 'sm' | 'lg', courseGuid: Nullable<string>) => {
  return `${process.env.REACT_APP_ASSET_BASE_URL}/${process.env.REACT_APP_URL_SUFFIX}/${tenantCode}/course/${courseGuid}-${size}.jpg`
}
