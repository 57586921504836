import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ASSET_PATHS } from '../constants/assets'
import { ContactForm, ContactUs, LoadingStates, Nullable, RootState } from '../types/assets'
import { getAssetURLGetter } from '../utils/assets'
import { fetchJSON } from '../utils/common'

export type ContactUsSlice = {
  loading: LoadingStates
  contactUs: {
    defaultContactForm: Nullable<ContactForm>
    additionalContactForms: Nullable<ContactForm[]>
    pageName: Nullable<string>
    selectedForm: Nullable<ContactForm>
  }
}

export const initialState: ContactUsSlice = {
  loading: 'idle',
  contactUs: {
    defaultContactForm: undefined,
    additionalContactForms: undefined,
    pageName: undefined,
    selectedForm: undefined,
  },
}

export const contactUsSlice = createSlice({
  name: 'contactUs',
  initialState,
  reducers: {
    setSelectedForm: (state, action) => {
      state.contactUs.selectedForm = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchContactUs.pending, (state, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchContactUs.fulfilled, (state, action) => {
      state.loading = 'succeeded'
      state.contactUs = action.payload
    })
  },
})

export const fetchContactUs = createAsyncThunk<ContactUs>(
  'contactUs/fetchContactUs',
  async (args?: any, props?: any) => {
    const { getState } = props

    const {
      settings: {
        config: { tenantCode, siteGuid, lastModifiedTimestamp },
      },
    }: RootState = getState()

    const getAssetURL = getAssetURLGetter(tenantCode as string, siteGuid as string, lastModifiedTimestamp as Date)

    const results: ContactUs = await fetchJSON(getAssetURL(ASSET_PATHS.contactUs))

    return {
      defaultContactForm: results.defaultContactForm,
      additionalContactForms: results.additionalContactForms,
      pageName: results.pageName,
      selectedForm: results.defaultContactForm,
    }
  },
)

export const { setSelectedForm } = contactUsSlice.actions

export default contactUsSlice.reducer
