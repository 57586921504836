import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryPropsWithComponent,
  FallbackProps,
} from 'react-error-boundary'
import styled from 'styled-components'
import { Button } from '../elements/Button'

export const StyledErrorBoundary = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1,
  h3 {
    text-align: center;
  }
`

const ErrorFallback = ({ error, resetErrorBoundary = () => window.location.reload() }: FallbackProps) => {
  return (
    <StyledErrorBoundary>
      <h1>Oops! Something went wrong.</h1>
      <h5>Error: {error.message}</h5>
      <Button
        onClick={resetErrorBoundary}
        label="Reload Page"
      />
    </StyledErrorBoundary>
  )
}

export const ErrorBoundary = ({
  FallbackComponent = ErrorFallback,
  ...props
}: Partial<ErrorBoundaryPropsWithComponent> & {
  children: React.ReactNode
}) => {
  return (
    <ReactErrorBoundary
      FallbackComponent={FallbackComponent}
      {...props}
    />
  )
}
