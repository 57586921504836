import styled from 'styled-components'

export const mobile = '600px'

export const StyledFooter = styled.div`
  margin-top: 1vh;
  text-align: center;
  color: #808080;
  font-size: 10pt;
  bottom: 0;

  img {
    margin-top: 10px;
  }

  @media only screen and (max-width: ${mobile}) {
    margin: 1rem;
  }
`
