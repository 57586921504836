import { useState } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { useAppSelector } from '../types/assets'

type LogoProps = {
  src?: string
}

export const StyledLogo = styled.div`
  img {
    display: block;
    /* max-width: 230px; */
    max-height: 95px;
    width: auto;
    height: auto;
  }

  :hover {
    cursor: pointer;
  }
`

export function Logo({ src }: LogoProps) {
  const path = useAppSelector(state => state.settings.config.path)
  const [imageSrc, setImageSrc] = useState(src)

  const navigate = useNavigate()
  const onClick = () => {
    navigate(`/${path}/`)
  }
  if (!imageSrc) return null

  return (
    <StyledLogo onClick={onClick}>
      <img
        src={src}
        alt="Logo"
        onError={() => setImageSrc(undefined)}
      />
    </StyledLogo>
  )
}
