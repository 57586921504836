import dayjs from 'dayjs'
import json from '../../node_modules/moment-timezone/data/meta/latest.json'

const { zones, countries } = json

type TimezoneCountry = {
  [key: string]: {
    name: string
    code: string
  }
}
const timeZoneCityToCountry: TimezoneCountry = {}

Object.keys(zones).forEach(z => {
  const cityArr = z.split('/')
  const city = cityArr[cityArr.length - 1]
  const keyTyped = z as keyof typeof zones
  const countryCode = zones[keyTyped].countries[0]
  timeZoneCityToCountry[city] = {
    name: countries[countryCode as keyof typeof countries].name,
    code: countryCode,
  }
})

export const fetchJSON = async <T = {} | {}[]>(url: string): Promise<T> => {
  try {
    return await (await fetch(url)).json()
  } catch (error) {
    throw new Error('Asset fetch failed.' + error)
  }
}

export const getObjectkeys = <T extends {}>(object: T) => {
  return Object.keys(object) as (keyof T)[]
}

export const urlify = (param: string) => {
  if (param?.trim().length > 0) {
    return param?.trim().replace(/\s+/g, '-').toLowerCase()
  } else {
    return ''
  }
}

export const formatDate = (date: any, format = 'DD/MM/YYYY') => {
  if (date) {
    return dayjs(date).format(format)
  }
  return ''
}

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}

export async function getUserCountry() {
  try {
    const data = getCountryFromTimezone()
    return Promise.resolve(data)
  } catch (error: any) {
    throw Error('Failed to verify User Country' + JSON.stringify(error))
  }
}

const getCountryFromTimezone = () => {
  if (!Intl) return {}

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const tzArr = timeZone.split('/')
  const city: any = tzArr[tzArr.length - 1]

  return {
    timeZone,
    region: tzArr[0],
    city,
    fullName: timeZoneCityToCountry[city].name,
    countryCode: timeZoneCityToCountry[city].code,
  }
}

export const getUnixTimestampForDate = (date: Date) => {
  return Math.floor(date.getTime() / 1000)
}
