import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import {
  SalesCourseIntakesCourseDto,
  SalesCourseIntakesDto,
  SalesCourseIntakesIntakeDto,
  SalesIntakeDto,
} from '@wisenet/salesapp-client'
import dayjs from 'dayjs'
import { cloudWatchLogger, salesApi } from '../api'
import { LoadingStates, RootState } from '../types/assets'
import { formatDate } from '../utils/common'
import { selectIntakeById } from './intakeSlice'

type CourseSlice = {
  loading: { all: LoadingStates; one: { [guid: string]: LoadingStates } }
  courses: SalesCourseIntakesCourseDto[]
  coursesWithIntakes: { [guid: string]: SalesCourseIntakesDto }
}

export const initialState: CourseSlice = {
  loading: { all: 'idle', one: {} },
  courses: [],
  coursesWithIntakes: {},
}

export const courseSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCourses.fulfilled, (state, action) => {
      state.loading.all = 'succeeded'
      state.courses = action.payload
    })
    builder.addCase(fetchCourses.pending, (state, action) => {
      state.loading.all = 'loading'
    })
    builder.addCase(fetchCourses.rejected, (state, action) => {
      state.loading.all = 'failed'
    })
    builder.addCase(fetchCourseWithIntakes.pending, (state, action) => {
      state.loading.one[action.meta.arg] = 'loading'
    })
    builder.addCase(fetchCourseWithIntakes.fulfilled, (state, action) => {
      if (!!action.payload.course) {
        state.loading.one[action.meta.arg] = 'succeeded'
        const replacingCourseIndex = state.courses.findIndex(
          course => course.courseGuid === action.payload.course?.courseGuid,
        )
        state.courses.splice(replacingCourseIndex, 1, action.payload.course!)
        state.coursesWithIntakes[action.payload.course.courseGuid!] = action.payload
      } else {
        state.loading.one[action.meta.arg] = 'failed'
      }
    })
  },
})

export const fetchCourses = createAsyncThunk<SalesCourseIntakesCourseDto[]>(
  'courses/fetchCourses',
  async () => {
    const courses: SalesCourseIntakesCourseDto[] = await salesApi.getCourses()
    cloudWatchLogger.info('Fetched Courses count', courses.length.toString())
    return courses
  },
  {
    condition: (args, { getState }) => {
      const state: any = getState()
      const fetchStatus = state.courses.loading
      if (fetchStatus === 'succeeded' || fetchStatus === 'loading') {
        // Already fetched or in progress, don't need to re-fetch
        return false
      }
    },
  },
)

export const fetchCourseWithIntakes = createAsyncThunk<SalesCourseIntakesDto, string, any>(
  'courses/fetchCourseWithIntakes',
  async (guid, thunkAPI) => {
    try {
      const courseAndIntakes: SalesCourseIntakesDto = await salesApi.getCourseIntakes({ guid })
      cloudWatchLogger.info('Fetched Single Course', courseAndIntakes?.course?.courseGuid || 'Course ID Not found')
      return courseAndIntakes
    } catch (error: any) {
      throw error
    }
  },
)

export const selectSortedCourses = createSelector(
  (state: RootState) => state.courses.courses,
  (courses: SalesCourseIntakesCourseDto[]) =>
    [...courses].sort((a, b) => (a?.publishName || '').localeCompare(b?.publishName || '')),
)

export const selectCourseFromCourseId = createSelector(
  (state: RootState) => state.courses.courses,
  (state: RootState, courseGuid: string) => courseGuid,
  (courses: SalesCourseIntakesCourseDto[], courseGuid: string) =>
    courses.find((course: SalesCourseIntakesCourseDto) => course?.courseGuid === courseGuid),
)

export const selectCourseOptionsForAdditionalCourses = createSelector(
  (state: RootState, intakeId: string, selectedCourseIds: string[]) => state.courses.courses,
  (state: RootState, intakeId: string, selectedCourseIds: string[]) => selectIntakeById(state, intakeId),
  (state: RootState, intakeId: string, selectedCourseIds: string[]) => selectedCourseIds,
  (courses: SalesCourseIntakesCourseDto[], intake: SalesIntakeDto, selectedCourseIds: string[]) => {
    const allowedCoursesIds = intake?.salesIntakeAllowedCourses?.map(c => c.allowedCourseGuid)
    return courses
      .filter(c => allowedCoursesIds?.includes(c.courseGuid) && !selectedCourseIds.includes(c.courseGuid!))
      .map(co => ({
        value: co.courseGuid || '',
        label: co.publishName || '',
      }))
  },
)

export const selectSortedIntakes = createSelector(
  (state: RootState) => state.courses.coursesWithIntakes,
  (state: RootState, courseGuid: string) => courseGuid,
  (coursesWithIntakes: { [guid: string]: SalesCourseIntakesDto }, courseGuid: string) =>
    (coursesWithIntakes?.[courseGuid]?.intakes || [])?.slice().sort((a, b) => {
      return dayjs(a?.intakeStartDate).isBefore(dayjs(b?.intakeStartDate)) ? -1 : 1
    }),
)

export const selectIntakeOptions = createSelector(
  (state: RootState) => state.courses.coursesWithIntakes,
  (state: RootState, courseGuid: string, intakeId: string) => courseGuid,
  (state: RootState, courseGuid: string, intakeId: string) => intakeId,
  (coursesWithIntakes: { [guid: string]: SalesCourseIntakesDto }, courseGuid, intakeId) =>
    (coursesWithIntakes?.[courseGuid]?.intakes || [])
      ?.filter(
        i =>
          // keep this for later incase we want to add it behind a switchboard
          // dayjs().isBetween(
          //   i?.applicationAllowStartDate ?? dayjs().subtract(1, "days"),
          //   i?.applicationAllowEndDate ?? dayjs().add(1, "days")
          // ) &&
          // dayjs().isBetween(
          //   i?.intakeStartDate ?? dayjs().subtract(1, "days"),
          //   i?.intakeEndDate ?? dayjs().add(1, "days")
          // ) &&
          //i?.vacancyCount && i?.vacancyCount > 0 && i.intakeGuid !== intakeId
          dayjs().isBefore(i?.applicationAllowEndDate ?? dayjs().add(1, 'days')) &&
          dayjs().isBefore(i?.intakeEndDate ?? dayjs().add(1, 'days')) &&
          i?.vacancyCount &&
          i?.vacancyCount > 0 &&
          i.intakeGuid !== intakeId,
      )
      .map(i => ({
        value: i.intakeGuid || '',
        label: `${i.publishName} - ${i.location} (${formatDate(i.intakeStartDate, 'DD MMM YYYY')} - ${formatDate(
          i.intakeEndDate,
          'DD MMM YYYY',
        )})`,
      })),
)

export const selectIndustriesOptions = createSelector(
  (state: RootState) => state.courses.courses,
  (courses: SalesCourseIntakesCourseDto[]) => {
    let industries: string[] = []
    let industryOptions: { label: string; value: string }[] = []
    courses?.forEach(course => {
      const industry = course.industry as string
      if (industries.indexOf(industry) < 0) {
        industries.push(industry)
        industryOptions.push({ label: industry, value: industry })
      }
    })
    return industryOptions
  },
)

export const selectLocationsOptions = createSelector(
  (intakes: SalesCourseIntakesIntakeDto[]) => intakes,
  (intakes: SalesCourseIntakesIntakeDto[]) => {
    let locations: string[] = []
    let locationsOptions: { label: string; value: string }[] = []
    intakes?.forEach(intake => {
      const location = intake.location as string
      if (locations.indexOf(location) < 0) {
        locations.push(location)
        locationsOptions.push({ label: location, value: location })
      }
    })
    return locationsOptions
  },
)

export const selectDeliveryOptions = createSelector(
  (intakes: SalesCourseIntakesIntakeDto[]) => intakes,
  (intakes: SalesCourseIntakesIntakeDto[]) => {
    const deliveryOptions: { label: string; value: string }[] = []
    intakes?.forEach(intake => {
      const intakeDeliveryOptions = intake.salesIntakeDeliveryOptions!
      intakeDeliveryOptions.forEach(delOp => {
        if (!deliveryOptions.find(dop => dop.value === delOp.deliveryOptionId?.toString())) {
          deliveryOptions.push({
            label: delOp.deliveryOptionName!,
            value: delOp.deliveryOptionId?.toString()!,
          })
        }
      })
    })

    return deliveryOptions
  },
)

export default courseSlice.reducer
