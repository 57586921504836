import Skeleton from 'react-loading-skeleton'
import { ContactUsBanner } from '../../components/contactUs/ContactUsBanner'
import { StyledFormBuilder } from '../../components/form/_styledtemp'

export const ContactUsSkeleton = ({ imageSrc }: { imageSrc: string }) => {
  return (
    <>
      <ContactUsBanner
        bannerImageSrc={imageSrc}
        contactUsInstructionText={''}
      >
        <Skeleton
          width={500}
          height={30}
        />
      </ContactUsBanner>
      <StyledFormBuilder>
        <div className="fb__element-input">
          <form>
            <div>
              <Skeleton
                width={240}
                height={24}
                style={{ marginBottom: '5px' }}
              />
              <Skeleton
                width={700}
                height={40}
                style={{ marginBottom: '20px' }}
              />
              <Skeleton
                width={320}
                height={24}
                style={{ marginBottom: '5px' }}
              />
              <Skeleton
                width={700}
                height={40}
                style={{ marginBottom: '20px' }}
              />
              <Skeleton
                width={280}
                height={24}
                style={{ marginBottom: '5px' }}
              />
              <Skeleton
                width={700}
                height={40}
                style={{ marginTop: '20px' }}
              />
            </div>
          </form>
        </div>
      </StyledFormBuilder>
    </>
  )
}
