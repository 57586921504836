import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router'
import { ThemeProvider } from 'styled-components'
import { NavBar } from '../../components/navigation/NavBar'
import { FAVICON_ELEMENT } from '../../constants/common'
import { Footer } from '../../elements/Footer'
import { NoNetworkBanner } from '../../elements/NoNetworkBanner'
import { TestModeBanner } from '../../elements/TestModeBanner'
import { DefaultThemeParams, useAppSelector } from '../../types/assets'
import { GlobalStyles, Wrapper } from './styles'

export const Home = () => {
  const navigate = useNavigate()

  const state = useAppSelector(state => state)

  const {
    settings: {
      config: { siteStatus, allowContact, allowCourses, path },
      theme: { brandingColors, faviconSrc },
    },
  } = state

  const [theme, setTheme] = useState<typeof DefaultThemeParams>(DefaultThemeParams)

  useEffect(() => {
    const page = window.location.pathname.split('/')[2]
    if (!page) {
      if (allowCourses) {
        navigate(`/${path}/courses`)
      } else if (allowContact) {
        navigate(`/${path}/contact`)
      } else {
        navigate(`/${path}/page-not-found`)
      }
    }
  })

  useEffect(() => {
    setTheme({
      ...theme,
      topBannerFill: brandingColors?.topBannerFillColor as string,
      topBannerColor: brandingColors?.topBannerFontColor as string,
      highlightColor: brandingColors?.highlightColor as string,
      buttonFill: brandingColors?.buttonColor as string,
      buttonColor: brandingColors?.buttonFontColor as string,
    })
    FAVICON_ELEMENT?.setAttribute('href', faviconSrc as string)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandingColors, faviconSrc])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Wrapper>
        <NoNetworkBanner />
        {siteStatus === 'Test' && <TestModeBanner />}
        <NavBar />
        <Outlet />
      </Wrapper>
      <Footer />
    </ThemeProvider>
  )
}
