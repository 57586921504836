import styled from 'styled-components'

const StyledTestModeBanner = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  align-items: center;
  justify-content: center;
  background-color: #fbda57;
  text-align: center;
  z-index: 2;
  transition: height 1s ease;
  color: #000;
  font-weight: 500;
  font-size: 11pt;
  height: 40px;
`

export const TestModeBanner = () => {
  return <StyledTestModeBanner>THIS SITE IS IN TEST MODE - FOR TESTING ONLY</StyledTestModeBanner>
}
