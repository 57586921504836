import styled from 'styled-components'

export type ParagraphProps = React.ComponentProps<'p'> & {
  children: React.ReactNode
}

const StyledP = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 39px;
  height: 50px;
`

export function Paragraph({ children, ...paragraphProps }: ParagraphProps) {
  return <StyledP>{children}</StyledP>
}
