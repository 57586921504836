import styled from 'styled-components'

export type BannerButtonsProps = {
  children: React.ReactNode
}

export const StyledBannerButtons = styled.ul`
  width: 100vw;
  padding: 0;
  margin: 30px 0 50px 0;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 20px;

  @media only screen and (max-width: 600px) {
    width: unset;
    margin: unset;
    flex-direction: column;

    li {
      margin-bottom: 30px;
    }
    li:not(:first-child) {
      margin-left: unset;
    }
  }
`

export function BannerButtons({ children }: BannerButtonsProps) {
  return <StyledBannerButtons>{children}</StyledBannerButtons>
}
