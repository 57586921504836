import { Link } from 'react-router-dom'
import styled from 'styled-components'
import image from '../assets/404.svg'
import { useAppSelector } from '../types/assets'

export const StyledError404 = styled.div`
  .error {
    text-align: center;
    padding: 16px;
    padding-top: 0;
    position: relative;
  }

  h2 {
    margin: 15px 0 0 0;
  }

  p {
    opacity: 0.8;
    font-size: 15px;
    font-weight: light;
    margin: 5px 0 20px;
  }
  .link-container {
    text-align: center;
  }
  a {
    text-transform: uppercase;
    font-size: 13px;
    background-color: ${props => props.theme.buttonFill};
    padding: 10px 15px;
    border-radius: 0;
    color: #fff;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    line-height: 1.5;
    text-decoration: none;
    letter-spacing: 1px;
  }
`

export const Error404 = () => {
  const { path } = useAppSelector(state => state.settings.config)
  return (
    <StyledError404>
      <div className="error">
        <img
          src={image}
          alt="404 page not found"
        />
        <h2>Oops! We could not find this page</h2>
        <p>Error code: 404</p>
      </div>
      <div className="link-container">
        <Link to={`/${path}`}>Go Back Home</Link>
      </div>
    </StyledError404>
  )
}
