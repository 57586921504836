import { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router'
import { ErrorBoundary } from './components/ErrorBoundary'
import { Error404 } from './elements/Error404'
import { Loader } from './elements/Loader'
import { ContactUsSkeleton } from './pages/Contact/ContactUsSkeleton'
import { Home } from './pages/Home'
import { useAppSelector } from './types/assets'

const Contact = lazy(() => import(/* webpackPrefetch:true, webpackChunkName: "contact-us" */ './pages/Contact'))
const Courses = lazy(() => import(/* webpackPrefetch:true, webpackChunkName: "courses" */ './pages/Courses'))
const Course = lazy(() => import(/* webpackPrefetch:true, webpackChunkName: "course" */ './pages/Course'))
const Intake = lazy(() => import(/* webpackPrefetch:true, webpackChunkName: "intake" */ './pages/Intake'))
const Resume = lazy(() => import(/* webpackPrefetch:true, webpackChunkName: "resume" */ './pages/Resume'))
const Application = lazy(
  () => import(/* webpackPrefetch:true, webpackChunkName: "application" */ './pages/Application'),
)
const PageNotFound = lazy(
  () => import(/* webpackPrefetch:true, webpackChunkName: "page-not-found" */ './elements/PageNotFound'),
)
const PaymentForm = lazy(() => import(/* webpackPrefetch:true, webpackChunkName: "payment" */ './pages/PaymentForm'))
const ApplicationSubmittedPage = lazy(
  () => import(/* webpackPrefetch:true, webpackChunkName: "application-submitted" */ './pages/ApplicationSubmitted'),
)

export function AppRoutes() {
  const {
    settings: {
      loading,
      config: { path, siteStatus, allowContact, allowCourses },
      theme: { contactUsBannerSrc },
    },
    contactUs: { loading: contactLoading },
  } = useAppSelector(state => state)
  return (
    <Suspense fallback={<Loader />}>
      {loading === 'succeeded' && siteStatus !== 'Inactive' ? (
        <Routes>
          <Route
            path={`${path}`}
            element={
              <ErrorBoundary>
                <Home />
              </ErrorBoundary>
            }
          >
            {allowContact && (
              <Route
                path={`contact`}
                element={
                  <Suspense fallback={<Loader />}>
                    <Contact />
                  </Suspense>
                }
              >
                <Route
                  path={`:formDisplayName`}
                  element={
                    <Suspense fallback={<Loader />}>
                      <Contact />
                    </Suspense>
                  }
                />
              </Route>
            )}

            <Route
              path={`courses/:id`}
              element={
                <Suspense fallback={<Loader />}>
                  <Course />
                </Suspense>
              }
            />

            {allowCourses && (
              <Route
                path={`courses`}
                element={
                  <Suspense fallback={<Loader />}>
                    <Courses />
                  </Suspense>
                }
              />
            )}

            <Route
              path={`intake/:id`}
              element={
                <Suspense fallback={<Loader />}>
                  <Intake />
                </Suspense>
              }
            />
            <Route
              path={`application/:id`}
              element={
                <Suspense fallback={<Loader />}>
                  <Application />
                </Suspense>
              }
            />
            <Route
              path={`payments/:id`}
              element={
                <Suspense fallback={<Loader />}>
                  <PaymentForm />
                </Suspense>
              }
            />
            <Route
              path={`application-submitted`}
              element={<ApplicationSubmittedPage />}
            />
            <Route
              path={`resume/:id`}
              element={
                <Suspense fallback={<Loader />}>
                  <Resume />
                </Suspense>
              }
            />
            <Route
              path={`*`}
              element={
                contactLoading === 'loading' ? <ContactUsSkeleton imageSrc={contactUsBannerSrc || ''} /> : <Error404 />
              }
            />
          </Route>
        </Routes>
      ) : loading === 'failed' || siteStatus === 'Inactive' ? (
        <Suspense fallback={<Loader />}>
          <PageNotFound status={siteStatus} />
        </Suspense>
      ) : (
        <Loader />
      )}
    </Suspense>
  )
}
